export class CheckinOutModel {
  guestId: number | string;
  checkInId: number;
  checkInDate: string;
  icon: string;
  checkOutDate: string;
  actualCheckOutDate: string;
  freeDate: string;
  roomTypeName: string;
  currentOccupant: string;
  occupantName: string;
  guestName: string | string[];
  daysLeft: number;
  roomNumber: number | string;
  roomStatus: string;
  roomId: number;
  payingGuestId: number;
  checkOutTime: string;
  payingGuestName: string;
  billingsId: number;
  lostKey: string;
  cost: number;
  numOfRooms: number;
  numOfGuests: number;
  type: string;
  hasOrder: string;
  openedDateTime: string;
  lastOrdered: string;
  totalBill: number;
  totalPaid: number;
  balance: number;
  employeeName: string;
  customer: string;
  tableName: string;
  paymentMethod: string;
  orderTableRoomId: string;
  comboName: string;
  currentTime: string;
  quantity: number;
  unitPrice: number;
  lineTotal: number;
  roomsOrder: [];
  orders: CheckinOutModel[];
  allInvoiceItems: CheckinOutModel[];
  createdDateTime: string;
  phoneNumber: string;
  numOfItems: number;
  statuss: string;
  paymentStatus: string;
  createdInvoiceNumber: string;
  invoiceDateTime: string;
  invoiceNum: string;
  checkOutStatus: string;
  amount: number;
  fdAmount: number;
  numOfOrders: number;
  toPay: boolean | string;
  comboProductId: string;
  paying: boolean | string;
  invoiceAccept: boolean | string;
  createInvoiceStatus: boolean | string;
  toBePaidStatus: boolean | string;
  orderProductId: string;
  invoiceAmount: number;
  taxFee: any;
  comboTax: number;

}
